import './App.css';
import { Watermark } from './watermark/watermark.js';

function App() {
  return (
    <div>
      <Watermark></Watermark>
    </div>
  );
}

export default App;
