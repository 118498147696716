import Jimp from 'jimp';
import React from 'react';
import { Form, InputGroup, Popover } from 'react-bootstrap';
import { Helper } from './helper';

/**
 * Handle the PDF upload
 */
export class UploadImage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            error: null
        };

        this.handle_upload = this.handle_upload.bind(this);
    }


    handle_upload(event){
        event.preventDefault();

        var fr = new FileReader();
        
        fr.onload = async (e) => {
            Jimp.read(e.target.result)
                .then((img) => {
                    this.props.on_file_upload(img);

                    this.setState({
                        error: null
                    });
                })
                .catch((err) => {
                    console.log("Unable to load image");
                    console.log(err);
                    this.setState({
                        error: true
                    });
                });
        }
              
        fr.readAsArrayBuffer(event.target.files[0]);
    }

    render() {
        const helper = (
            <Popover id="popover-basic">
                <Popover.Header as="h3">Custom image</Popover.Header>
                <Popover.Body>
                    Use a custom image as watermark.
                </Popover.Body>
            </Popover>
        );

        return (
           <div class="form-group d-flew flew-column w-100">
                <div class="d-flex flex-row w-100">
                    <Form.Label>Custom image</Form.Label>

                    <div class="ms-auto">
                        <Helper helper = { helper }></Helper>
                    </div>
                </div>
                
                <InputGroup hasValidation>
                    <Form.Control 
                        type="file" accept=".jpg, .png"
                        onChange = { this.handle_upload }
                        isInvalid = { this.state.error }
                    ></Form.Control>
                    
                    { this.state.error &&
                        <Form.Control.Feedback type="invalid">
                            Unable to load the image.
                        </Form.Control.Feedback>
                    }
                </InputGroup>
            </div>
        )
    }
}