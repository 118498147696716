import React from 'react';
import { Download } from './download';
import { WLine } from './wline';
import { UploadPDF } from './wuploadpdf';
import { WSlider } from './wslider';
import { KoFi } from '../kofi/kofi';
import { Accordion } from 'react-bootstrap';
import { IPlus } from '../icon/plus';
import { UploadImage } from './wuploadimg';

/**
 * Control panel to upload the pdf, set the parameter and download the result
 */
export class Control extends React.Component {

    render() {
        const lines = this.props.lines.map((line) =>             
            <WLine line={line}
                   on_delete={ this.props.on_line_delete }
                   on_change={ this.props.on_line_change }
            ></WLine>
        );

        return (
            <div class="d-flex flex-column p-3 w-100">
                <UploadPDF on_file_upload={ this.props.on_file_upload }></UploadPDF>
                
                <WSlider value = { this.props.repets }
                         on_change = { this.props.on_repet_change }
                         min="1" max="42" step="1"
                         text="Number of repetitions"
                         help_title="Repetitions"
                         help_content="
                            Number of times to repet the watermark per document pages.
                            The watermark will be evenly distributed on the page.
                         "
                ></WSlider>

                <WSlider value = { this.props.rotation }
                         on_change = { this.props.on_rotation_change }
                         min="0" max="359" step="1"
                         text="Watermark rotation"
                         help_title="Rotation"
                         help_content="
                            Image rotation in degrese.
                            0 degree means the watermark will be horizontally aligned from left to right.
                            180 degree means the watermark will be horizontally aligned from rigt to left (with downward text).
                         "
                ></WSlider>

                <WSlider value = { this.props.opacity }
                         on_change = { this.props.on_opacity_change }
                         min="1" max="100" step="1"
                         text="Watermark opacity"
                         help_title="Opacity"
                         help_content="
                            Change the watermark opacity.
                            100 means the watermark will be totally opac and 0 means the watermark will be totally transparent.
                         "
                ></WSlider>

                <Accordion activeKey = { this.props.type }>
                    <Accordion.Item eventKey="lines">
                        <Accordion.Header onClick = { () => this.props.on_type_change('lines') }>
                            Text lines
                        </Accordion.Header>
                        <Accordion.Body>
                            <div class="d-flex flex-row justify-content-end">
                                <small class="text-primary" role="button" onClick={ this.props.on_line_new }>
                                    <IPlus />
                                </small>
                            </div>

                            <div>
                               {lines}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="image">
                        <Accordion.Header onClick = { () => this.props.on_type_change('image') }>
                            Custom image
                        </Accordion.Header>
                        <Accordion.Body>
                            <UploadImage on_file_upload = { this.props.on_custom_change }></UploadImage>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                
                <div class="d-flex flex-row justify-content-center">
                    <Download pdf={ this.props.pdf }></Download>
                </div>

                <div class="d-flex flex-row justify-content-center">
                   <KoFi color="#29ab00" id="V7V177YI2" label="Buy me a Ko-fi" />
                </div>
            </div>
        );
    }
}